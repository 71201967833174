import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 员工管理
 **************************************/
/**
 * 员工管理查看
 */
export function index_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/user/index',
        method: 'get',
        params:data
    })
}

/**
 * 员工管理删除
 */
export function index_del(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/user/del',
        method: 'get',
        params:data
    })
}


/**
 * 员工管理编辑
 */
export function index_edit(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/user/edit',
        method: 'post',
        data
    })
}


/**
 *  员工管理商城列表
 */
export function index_admin_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/user/list',
        method: 'get',
        params:data
    })
}
